import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faWallet } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../contexts/AuthContext';
import { getCurrentUser, getProjectIdByUserId, isProUser } from '../api/api';
import { getRandomPredefinedColor, getTextColor } from '../utils/colorUtilsHomePage';
import { stack as Menu } from 'react-burger-menu';
import Logo from '../assets/icons/Logo Bannière YG 2024 Violet.png'; // Logo Bannière YG 2024 Violet.png'
import LogoPro from '../assets/icons/Logo Bannière YG Pro Vt 2024.png'
import '../Header.css';  // Import the Header.css file here
import Modal from './Modal';

export default function Header({ currentPage }) {
  const { isLoggedIn, logOut } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false); // État pour le menu déroulant
  const dropDownRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isProfessional, setIsProfessional] = useState(null);

  const bgColor = getRandomPredefinedColor();
  const textColor = getTextColor(bgColor);

  const toggleDropDown = (e) => {
    e.stopPropagation(); // Prevent bubbling
    setShowDropDown((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowDropDown(false); // Collapse dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchProjectId = async () => {
      try {
        const userResponse = await getCurrentUser();
        const userId = userResponse.data._id;

        const projectData = await getProjectIdByUserId(userId);
        setProjectId(projectData.projectId);

        const isProData = await isProUser();
        setIsProfessional(isProData.data?.isProfessional || isProData.isProfessional);
      } catch (error) {
        console.error("Erreur lors du userId ou projectId:", error);
      }
    };
    fetchProjectId();
  }, []);

  const handleLogout = () => {
    logOut();
    setShowModal(true);
    setShowDropDown(false);
    history.push('/#Accueil');
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenuAndNavigate = (path) => {
    setMenuOpen(false);
    setTimeout(() => {
      if (path.startsWith('#')) {
        const currentPath = window.location.pathname;
        if (currentPath !== '/') {
          window.location.href = `/${path}`;
        } else {
          window.location.hash = path;
        }
      } else {
        history.push(path);
      }
    }, 300); // delay navigation to ensure menu close animation completes
  };

  const shouldUseBurgerMenu = () => {
    const burgerMenuPaths = [
      '/creer/lieu-du-mariage/',
      '/creer/depot-fichier/',
      '/creer/liste-d-invites/',
      '/creer/personnaliser-reservation/'
    ];
    return burgerMenuPaths.some(basePath => location.pathname.startsWith(basePath));
  };

  const isSmallScreen = window.innerWidth <= 768;

  return (
    <header className={`${scrolled ? 'header-blur' : ''} ${shouldUseBurgerMenu() ? 'header-burger' : 'header'} fixed w-full z-10 transition-colors duration-500`}>
      <div className={`${shouldUseBurgerMenu() ? 'container-burger' : 'container'} mx-auto`}>
        <div className="logo-yg-container">
          {(!isProfessional &&
            <Link
              to="/#Accueil"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <div className="logo-yg">
                <img src={Logo} alt="YG Logo" />
              </div> {/* Image standard */}
            </Link>
          )}

          {(isProfessional &&
            <Link
              to="/#Accueil"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <div className="logo-yg">
                <img src={LogoPro} alt="YG Logo Pro" />
              </div> {/* Image standard */}
            </Link>
          )}
        </div>
        {isSmallScreen ? (
          <div className="md:hidden">
            <Menu left isOpen={menuOpen} onStateChange={handleMenuStateChange} crossButtonClassName="bm-cross">
              <div onClick={() => closeMenuAndNavigate('#Accueil')} className="header-link-burger" style={{ color: scrolled ? textColor : '#ffffff' }}>
                Accueil
              </div>
              <div onClick={() => closeMenuAndNavigate('#nos-services')} className="header-link-burger" style={{ color: scrolled ? textColor : '#ffffff' }}>
                Nos Services
              </div>
              <div onClick={() => closeMenuAndNavigate('#about')} className="header-link-burger" style={{ color: scrolled ? textColor : '#ffffff' }}>
                À propos
              </div>
              <div onClick={() => closeMenuAndNavigate('/devenez-partenaire')} className="header-link-burger" style={{ color: scrolled ? textColor : '#ffffff' }}>
                Professionnels
              </div>
              <div onClick={() => closeMenuAndNavigate('/contact')} className="header-link-burger" style={{ color: scrolled ? textColor : '#ffffff' }} isActive={() => currentPage === 'Contact'}>
                Contact
              </div>
              {isLoggedIn ? (
                <>
                  {!isProfessional && (
                    <div onClick={() => closeMenuAndNavigate('/creer')} className="header-link-burger">Créer</div>
                  )}
                  <div onClick={() => closeMenuAndNavigate('/mon-compte')} className="header-link-burger">Mon compte</div>
                  {projectId && !isProfessional && (
                    <div onClick={() => closeMenuAndNavigate(`/dashboard/${projectId}`)} className="header-link-burger">
                      Dashboard<FontAwesomeIcon icon={faWallet} style={{ color: "#5465ff", marginLeft: '10px' }} />
                    </div>
                  )}
                  <div onClick={() => { closeMenu(); handleLogout(); }} className="header-link-burger" style={{ fontSize: '1rem' }}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
                  </div>
                </>
              ) : (
                <div onClick={() => closeMenuAndNavigate('/login-register')} className="header-link-burger">
                  <button className="connexion-button" style={{ backgroundColor: scrolled ? bgColor : '#5465ff', color: scrolled ? textColor : '#ffffff' }}>Connexion</button>
                </div>
              )}
            </Menu>
          </div>
        ) : (
          shouldUseBurgerMenu() ? (
            <div className="md:block">
              <Menu left isOpen={menuOpen} onStateChange={handleMenuStateChange} className="bm-menu-large" crossButtonClassName="bm-cross-large">
                <div onClick={() => closeMenuAndNavigate('#Accueil')} className="header-link-burger bm-item-large" style={{ color: scrolled ? textColor : '#ffffff' }}>
                  Accueil
                </div>
                <div onClick={() => closeMenuAndNavigate('#nos-services')} className="header-link-burger bm-item-large" style={{ color: scrolled ? textColor : '#ffffff' }}>
                  Nos Services
                </div>
                <div onClick={() => closeMenuAndNavigate('#about')} className="header-link-burger bm-item-large" style={{ color: scrolled ? textColor : '#ffffff' }}>
                  À propos
                </div>
                <div onClick={() => closeMenuAndNavigate('/devenez-partenaire')} className="header-link-burger bm-item-large" style={{ color: scrolled ? textColor : '#ffffff' }}>
                  Professionnels
                </div>
                <div onClick={() => closeMenuAndNavigate('/contact')} className="header-link-burger bm-item-large" style={{ color: scrolled ? textColor : '#ffffff' }} isActive={() => currentPage === 'Contact'}>
                  Contact
                </div>
                {isLoggedIn ? (
                  <>
                    <div onClick={() => closeMenuAndNavigate('/mon-compte')} className="header-link-burger bm-item-large">Mon compte</div>
                    {projectId && !isProfessional && (
                      <div onClick={() => closeMenuAndNavigate(`/dashboard/${projectId}`)} className="header-link-burger bm-item-large">
                        Dashboard<FontAwesomeIcon icon={faWallet} style={{ color: "#5465ff", marginLeft: '10px' }} />
                      </div>
                    )}
                    <div onClick={() => { closeMenu(); handleLogout(); }} className="header-link-burger bm-item-large" style={{ fontSize: '1rem' }}>
                      <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
                    </div>
                  </>
                ) : (
                  <div onClick={() => closeMenuAndNavigate('/login-register')} className="header-link-burger bm-item-large">
                    <button className="connexion-button" style={{ backgroundColor: scrolled ? bgColor : '#5465ff', color: scrolled ? textColor : '#ffffff' }}>Connexion</button>
                  </div>
                )}
              </Menu>
            </div>
          ) : (
            <nav className="flex items-center space-x-7 hidden md:flex">
              <ul className="flex items-center space-x-7">
                <li className='text-1xl roboto'>
                  <HashLink
                    className="header-link"
                    smooth to="/#Accueil"
                    style={{ color: scrolled ? textColor : '#ffffff' }}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  >
                    Accueil
                  </HashLink>
                </li>
                <li className='text-1xl roboto'>
                  <HashLink
                    className="header-link"
                    smooth to="/#nos-services"
                    style={{ color: scrolled ? textColor : '#ffffff' }}
                  >
                    Nos Services
                  </HashLink>
                </li>
                <li className='text-1xl roboto'>
                  <HashLink
                    className="header-link"
                    smooth to="/#about"
                    style={{ color: scrolled ? textColor : '#ffffff' }}
                  >
                    À propos
                  </HashLink>
                </li>
                <li className='text-1xl roboto'>
                  <HashLink
                    className="header-link"
                    smooth to="/devenez-partenaire"
                    style={{ color: scrolled ? textColor : '#ffffff' }}
                  >
                    Professionnels
                  </HashLink>
                </li>
                <li className='text-1xl roboto'>
                  <NavLink
                    className="header-link"
                    exact to="/contact"
                    style={{ color: scrolled ? textColor : '#ffffff' }}
                    isActive={() => currentPage === 'Contact'}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>

              {/* Conditional Buttons */}
              <div className="login-register relative">
                {isLoggedIn ? (
                  <>
                    <div className="flex items-center space-x-7">
                      {(!isProfessional &&
                        <li className='text-1xl roboto'>
                          <Link
                            className="header-link"
                            to="/creer"
                            style={{ color: scrolled ? textColor : '#ffffff' }}
                          >
                            Créer
                          </Link>
                        </li>
                      )}
                      <button
                        className='button-login'
                        onClick={toggleDropDown}
                      >
                        <FontAwesomeIcon icon={faUser} style={{ color: scrolled ? textColor : '#ffffff' }} />
                      </button>
                    </div>
                    {isLoggedIn && showDropDown && (
                      <div ref={dropDownRef} className="dropdown-menu absolute">
                        <Link
                          to="/mon-compte"
                          onClick={() => {
                            setShowDropDown(false)
                          }}
                        >
                          Mon compte
                        </Link>
                        {projectId && !isProfessional && (
                          <Link
                            to={`/dashboard/${projectId}`}
                            onClick={() => {
                              setShowDropDown(false)
                            }}
                          >
                            Dashboard <FontAwesomeIcon icon={faWallet} style={{ color: "#5465ff", marginLeft: '10px' }} />
                          </Link>
                        )}
                        <button
                          onClick={() => {
                            handleLogout(); // Perform logout
                            setShowDropDown(false); // Collapse dropdown
                          }}
                          style={{ fontSize: '1rem' }}
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <Link to="/login-register">
                    <button
                      className="connexion-button"
                      style={{ backgroundColor: scrolled ? bgColor : '#5465ff', color: scrolled ? textColor : '#ffffff' }}
                    >
                      Connexion
                    </button>
                  </Link>
                )}
                {showModal && (
                  <Modal closeModal={closeModal}>
                    <p>Vous avez bien été déconnecté.</p>
                  </Modal>
                )}
              </div>
            </nav>
          )
        )}
      </div>
    </header>
  );
}