import React, { useState, useRef, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import { toHTML } from '@portabletext/to-html';
import '../../VenueFormQuill.css'

Quill.register('modules/blotFormatter', BlotFormatter);

const VenueQuillForm = ({ value = '', onChange }) => {
    const [messageContent, setMessageContent] = useState('');
    const quillRef = useRef(null);

    useEffect(() => {
        if (Array.isArray(value)) {
            const htmlContent = toHTML(value); // Convert blocks to HTML
            setMessageContent(htmlContent);
        } else {
            setMessageContent(value || ''); // Handle plain HTML or empty values
        }
    }, [value]);
    
    const handleContentChange = (newContent) => {
        setMessageContent(newContent);
        
        if (onChange) { onChange(newContent); }
    };

    const toolbarOptions = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],  // formats de police
                [{ 'size': ['small', false, 'large', 'huge'] }],  // taille de la police
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],                
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // listes
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // indentation
                [{ 'align': [] }],                                // alignement
                // liens, images, vidéos
            ]
        }
    };
    const formatsQuill = [        
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'header', 'font', 'size',
        'list', 'bullet', 'indent', 'align',
        'link', 'image', 'background',
    ];

    const modules = {
        toolbar: toolbarOptions.toolbar,
        // handlers: toolbarOptions.handlers,
        blotFormatter: {},
    };   

    return (
        <div className='container-description'>
        <ReactQuill
            ref={quillRef}
            theme="snow"
            value={messageContent}
            onChange={handleContentChange}
            modules={modules}
            formats={formatsQuill}
            placeholder={'Présentez votre lieu...'}
            className='custom-quill-container ql-container'
        />
        </div>
    )
};

export default VenueQuillForm;