import React, { useState, useEffect } from 'react';

import { getGoogleReviews } from '../api/api';
import { FcGoogle } from "react-icons/fc";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // Import star icons
import styles from '../GoogleReviewsCarousel.module.css'; // Import your custom CSS

const GoogleReviewsCarousel = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviewsFromMongo = async () => {
      try {
        const reviewsData = await getGoogleReviews(); // Fetch reviews from MongoDB
        setReviews(reviewsData || []); // Set the reviews state
      } catch (error) {
        console.error('Error fetching reviews from MongoDB:', error);
      }
    };

    fetchReviewsFromMongo();
  }, []);

  // Function to render stars for ratings
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Full stars
    const halfStar = rating % 1 >= 0.5; // Half star
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Empty stars

    return (
      <div className={styles.starsContainer}>
        {[...Array(fullStars)].map((_, i) => <FaStar key={`full-${i}`} className={styles.starFilled} />)}
        {halfStar && <FaStarHalfAlt className={styles.starHalf} />}
        {[...Array(emptyStars)].map((_, i) => <FaRegStar key={`empty-${i}`} className={styles.starEmpty} />)}
      </div>
    );
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.titleContainer}>
        <FcGoogle className={styles.googleIcon} />
        <h3 className={styles.title}>Avis Google</h3>
      </div>
      {reviews.length > 0 ? (
        <div className={styles.carousel}>
          {reviews.map((review, index) => (
            <div key={index} className={styles.carouselItem}>
              <div className={styles.authorContainer}>
                <img
                  src={review.profile_photo_url}
                  alt={`${review.author_name}'s profile`}
                  className={styles.authorImage}
                />
                <strong>
                  <a
                    href={review.author_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.authorName}
                  >
                    {review.author_name}
                  </a>
                </strong>
              </div>
              <div>{renderStars(review.rating)} {/* Render stars for rating */}</div>
              <p>
                {review.language === review.original_language ? review.text : review.text}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles.noReviews}>Pas d'avis disponibles</p>
      )}
    </div>
  );
};

export default GoogleReviewsCarousel;
