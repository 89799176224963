import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTrashAlt } from 'react-icons/fa';
import styles from '../../DeleteModal.module.css';

const DeleteModal = ({ isOpen, onClose, onConfirm, entityName }) => {
    const [inputValue, setInputValue] = useState('');
    const confirmationText = 'Je comprends';

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            overflow: 'auto',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%',
                    },
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%',
                    },
                }));
            } else {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%',
                    },
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
            <div className={styles.titleContainer}>
                <FaTrashAlt style={{ color: '#ff4d4f', fontSize:'24px', marginRight:'10px' }} />
                <h2 className={styles.modalTitle}>
                    Supprimer {entityName} ?
                </h2>                
            </div>
            <p className={styles.modalText}>
                Cette action est irréversible. Si vous supprimez {entityName}, les données ne pourront pas être restaurées.
            </p>
            <p className={styles.modalText}>
                Pour confirmer, veuillez écrire "<strong>{confirmationText}</strong>" ci-dessous :
            </p>
            <input
                type="text"
                placeholder={confirmationText}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className={styles.modalInput}
            />
            <div className={styles.modalActions}>
                <button className={styles.cancelButton} onClick={onClose}>
                    Annuler
                </button>
                <button
                    className={styles.deleteButton}
                    onClick={onConfirm}
                    disabled={inputValue !== confirmationText}
                >
                    Supprimer
                </button>
            </div>
        </Modal>
    );
};

export default DeleteModal;
