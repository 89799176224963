import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCurrentUser, isProUser, isPublicProUser } from '../api/api';
import { fetchBuildingData, fetchVenueData, createOrUpdateVenueInSanity, createOrUpdateBuildingInSanity, deleteFromSanity } from '../components/ProPortal/VenueSanityHelpers';

const VenueContext = createContext();

export const useVenueContext = () => useContext(VenueContext);

export const VenueContextProvider = ({ children }) => {
    const [venues, setVenues] = useState([]); // Stores all venues
    const [activeVenue, setActiveVenue] = useState(null);
    const [activeBuilding, setActiveBuilding] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isProfessional, setIsProfessional] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const userResponse = await getCurrentUser();             
                setUserId(userResponse.data._id);
                const userDataId = userResponse.data._id
                console.log('userResponse', userResponse)

                const isProData = await isProUser();
                setIsProfessional(isProData.data?.isProfessional || isProData.isProfessional);

                console.log('userId', userId)
                const fetchedVenues = await fetchVenueData(userDataId);
                console.log('fetchedVenues', fetchedVenues)
                setVenues(fetchedVenues || []);
                if (fetchedVenues.length > 0) {
                    setActiveVenue(fetchedVenues[0]); // Set the first venue as active

                    const fetchedBuildings = await fetchBuildingData(fetchedVenues[0]._id);
                    const venueBuildings = fetchedBuildings.filter(
                        building => building.weddingVenue === fetchedVenues[0]._id
                    );

                    // Attach the fetched buildings to the active venue but keep activeBuilding null
                    setActiveVenue(prev => ({
                        ...prev,
                        buildings: venueBuildings,
                    }));
                } else {
                    setActiveVenue(null);
                }

                setActiveBuilding(null); // Ensure activeBuilding is null on initialization
            } catch (error) {
                console.error("Error initializing data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, []);

    // Add a new venue or building
    const addVenue = async (newVenue) => {
        try {
            setActiveVenue(newVenue);
            await createOrUpdateVenueInSanity(newVenue);
            const updatedVenues = await fetchVenueData(userId);
            setVenues(updatedVenues);

            console.log('Venue added successfully');
        } catch (error) {
            console.error('Error adding venue:', error);
        }
    };

    const addBuilding = async (venueId, newBuilding) => {
        try {

            setActiveVenue(prev => ({
                ...prev,
                buildings: [...(prev.buildings || []), newBuilding],
            }));

            await createOrUpdateBuildingInSanity(newBuilding);

            setVenues((prev) =>
                prev.map((venue) =>
                    venue._id === venueId
                        ? {
                            ...venue,
                            buildings: [...(venue.buildings || []), newBuilding],
                        }
                        : venue
                )
            );

            // Fetch the updated buildings for validation
            // const updatedBuildings = await fetchBuildingData(venueId);
            // setActiveVenue(prev => ({
            //     ...prev,
            //     buildings: updatedBuildings,
            // }));

            // const savedBuilding = updatedBuildings.find(building => building.buildingName === newBuilding.buildingName) || newBuilding;
            // setActiveBuilding(savedBuilding);

        } catch (error) {
            console.error('Error adding building:', error);
        }
    };

    const deleteVenue = async (venueId) => {
        try {
            await deleteFromSanity(venueId); // Call the helper to delete the venue
            setVenues((prev) => prev.filter((venue) => venue._id !== venueId)); // Remove from local state
            setActiveVenue(null); // Reset activeVenue if it was the one deleted
            console.log(`Venue with ID ${venueId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting venue with ID ${venueId}:`, error);
        }
    };

    const deleteBuilding = async (buildingId, venueId) => {
        try {
            await deleteFromSanity(buildingId); // Call the helper to delete the building
            setActiveVenue((prev) => ({
                ...prev,
                buildings: prev.buildings.filter((building) => building._id !== buildingId), // Remove from buildings
            }));
            setActiveBuilding(null); // Reset activeBuilding if it was the one deleted
            console.log(`Building with ID ${buildingId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting building with ID ${buildingId}:`, error);
        }
    };

    const createEmptyVenueForm = () => {
        setActiveVenue({
            _id: '',
            name: '',
            logo: null,
            description: '',
            address: '',
            latitude: '',
            longitude: '',
            website: '',
            Wifi: false,
            SmokingPolicy: [],
            petPolicies: [],
            disabledAccessOptions: [],
            maxCapacity: '',
            park: '',
            intReceptionPlaces: '',
            intReceptionArea: '',
            extReceptionPlaces: '',
            extReceptionArea: '',
            Entertainment: [],
            Parking: {
                ParkingType: [],
                TotalSpaces: '',
                Equipment: [],
                Security: [],
            },
            linenTowelDetails: [],
            linenRate: '',
            towelRate: '',
            rentableItems: [],
            gallerie: [],
            virtualTourUrl: '',
            createdBy: userId,
        });
    };

    const createEmptyBuildingForm = () => {
        setActiveBuilding({
            _id: '',
            buildingName: '',
            totalBeds: '',
            rooms: [],
            suites: [],
            images: [],
            createdBy: userId,
            weddingVenue: activeVenue._id,
            venueName: activeVenue.name
        });
    };

    // Context value
    const contextValue = {
        venues,
        activeVenue,
        activeBuilding,
        userId,
        isProfessional,
        isLoading,
        addVenue,
        addBuilding,
        deleteVenue,
        deleteBuilding,
        createEmptyVenueForm,
        createEmptyBuildingForm,
        setActiveVenue,
        setActiveBuilding,
    };

    return <VenueContext.Provider value={contextValue}>{children}</VenueContext.Provider>;
};