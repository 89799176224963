import React, { useEffect, useState } from 'react';
import venueFormScreenshot from '../assets/Images/create_venue_form.gif'; // Replace with actual screenshot
import buildingFormScreenshot from '../assets/Images/create_building_form.gif'; // Replace with actual screenshot
import { useHistory } from 'react-router-dom';
import styles from '../PartnerRegistration.module.css'; // Adjust if needed for new styles

const VenueCreationSection = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = () => {
        history.push('/login-register');
    };

    return (
        <section className={styles.venueCreationSection}>
            <h2 className={styles.sectionTitle}>Créez votre lieu et configurez vos bâtiments facilement</h2>
            <div className={styles.stepContainer}>
                <div className={styles.step}>
                    <div className={styles.textContainer}>
                        <h3>1. Créez votre lieu</h3>
                        <p>
                            Remplissez les informations de base pour votre lieu, ajoutez un logo, une description détaillée,
                            et précisez les options comme la capacité d’accueil, le WiFi, et plus encore.
                        </p>
                    </div>
                    <div className={styles.imageContainer}>
                        <img src={venueFormScreenshot} alt="Aperçu du formulaire de lieu" className={styles.formScreenshot} />
                    </div>
                </div>
                <div className={styles.step}>
                    {isSmallScreen ? (
                        <>
                            <div className={styles.textContainer}>
                                <h3>2. Ajoutez vos bâtiments</h3>
                                <p>
                                    Configurez vos bâtiments en indiquant leurs caractéristiques, ajoutez des chambres, des suites,
                                    et personnalisez leur galerie photo pour une présentation optimale.
                                </p>
                            </div>
                            <div className={styles.imageContainer}>
                                <img src={buildingFormScreenshot} alt="Aperçu du formulaire de bâtiment" className={styles.formScreenshot} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.imageContainer}>
                                <img src={buildingFormScreenshot} alt="Aperçu du formulaire de bâtiment" className={styles.formScreenshot} />
                            </div>
                            <div className={styles.textContainer}>
                                <h3>2. Ajoutez vos bâtiments</h3>
                                <p>
                                    Configurez vos bâtiments en indiquant leurs caractéristiques, ajoutez des chambres, des suites,
                                    et personnalisez leur galerie photo pour une présentation optimale.
                                </p>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.step}>
                    <div className={styles.thirdStep}>
                        <h3>3. Pourquoi c'est important</h3>
                        <p>
                            En créant les formulaires de vos lieux et bâtiments, vous simplifiez considérablement le processus de réservation pour les invités des futurs mariés tout en gardant un contrôle total sur votre image et votre communication. Cette approche vous permet de mettre en avant votre établissement de manière professionnelle et attrayante. Vous offrez également une expérience fluide et sans stress aux futurs mariés et à leurs invités, qui peuvent facilement consulter les informations nécessaires et effectuer leurs réservations directement sur notre plateforme, en toute simplicité et transparence.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.ctaSection}>
                <p>
                    N'attendez plus une seconde et créez votre premier lieu sur Your Guests en créant votre compte <strong>professionnel</strong> ou en vous connectant.
                </p>
                <button className={styles.ctaButton} onClick={handleButtonClick}>
                    Créer un compte / Se connecter
                </button>
            </div>
        </section>
    );
};

export default VenueCreationSection;