import React, { useEffect, useRef } from "react";
import { urlFor } from "./imageUrlBuilder";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import styles from '../ModalVenue.module.css';

const blockArrayToText = (blockArray) => {
    return blockArray
        .map(block => block.children ? block.children.map(child => child.text).join(' ') : '')
        .join(' ');
};

// Truncate function to limit text length
const truncateText = (text, maxLength) => {
    if (!text) return ''; // Ensure text exists before truncating
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const ModalVenue = ({ isOpen, onClose, content, onSave }) => {
    const modalRef = useRef();
    const imagesContainerRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const scrollImages = (direction) => {
        const scrollAmount = 200;

        if (imagesContainerRef.current) {
            imagesContainerRef.current.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
        }
    };

    if (!isOpen) {
        return null;
    }

    // Convert description to plain text, then truncate
    const plainDescription = Array.isArray(content.description) 
    ? blockArrayToText(content.description) 
    : (content.description || '');
    const truncatedDescription = truncateText(plainDescription, 250);

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContent} ref={modalRef}>
                <div className={styles.modalTop}>
                    {content.logo && (
                        <img 
                            src={urlFor(content.logo).auto('format').width(100).url()} 
                            alt={content.alt} 
                            className={styles.modalLogo}
                        />
                    )}
                    <h2 className={styles.modalTitle}>{content.title}</h2>
                </div>
                <div className={styles.modalDescription}>
                    {truncatedDescription}
                </div>
                <div className={styles.modalImagesContainer}>
                    <div className={styles.scrollArrow} onClick={() => scrollImages('left')}>
                        <FaChevronLeft/>
                    </div>
                    <div className={styles.modalImages} ref={imagesContainerRef}>
                        {content.gallery && content.gallery.length > 0 ? (
                            content.gallery.slice(0, 5).map((image, index) => (
                                <img
                                    key={index}
                                    src={urlFor(image.asset).auto('format').width(200).url()}
                                    alt={`Image ${index + 1}`}
                                    className={styles.modalImage}
                                />
                            ))
                        ) : (
                            <p>Pas d'images disponibles.</p>
                        )}
                    </div>
                    <div className={styles.scrollArrow} onClick={() => scrollImages('right')}>
                    <FaChevronRight/>
                    </div>
                </div>
                <div className={styles.modalActions}>
                    <button className={styles.modalButton} onClick={onClose}>Revenir</button>
                    <button className={styles.modalButton} onClick={onSave}>Confirmer</button>
                </div>
            </div>
        </div>
    );
};

export default ModalVenue;
