// Footer.jsx
import React from 'react';
import { SocialIcon } from 'react-social-icons';
import styles from '../Footer.module.css';
import { useHistory } from 'react-router-dom';

function Footer() {
  const history = useHistory();

  const navigate = (path) => {
    history.push(path);
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <hr className={styles.divider} />
        <ul className={styles.links}>
          <li onClick={() => navigate('/privacy')}>Politique de confidentialité</li>
          <li onClick={() => navigate('/terms')}>Conditions d'utilisation</li>
          <li onClick={() => navigate('/cookies')}>Informations sur les cookies</li>
        </ul>
        <div className={styles.socialIcons}>
          <SocialIcon url="https://www.instagram.com/yourguests_/#" style={{ height: 35, width: 35 }} />
          <SocialIcon url="https://www.facebook.com/profile.php?id=61561240913724" style={{ height: 35, width: 35 }} />
          <SocialIcon url="https://x.com/YourGuests" style={{ height: 35, width: 35 }} />
          <SocialIcon url="https://www.linkedin.com/company/your-guests/" style={{ height: 35, width: 35 }} />
        </div>
        <p> © 2024, Invited SAS </p>
      </div>
    </footer>
  );
}

export default Footer;
