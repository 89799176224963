import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImageToSanity } from './VenueSanityHelpers';
import styles from '../../DnDImgPreview.module.css'

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: '5px',
    justifyContent: 'center'
};

const thumb = {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 4,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const removeButton = {
    position: 'absolute',
    top: 4,
    right: 4,
    backgroundColor: '#ff4d4f',
    color: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: 20,
    height: 20,
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

function Previews({ onUpload, removeImage, existingImages = [] }) {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        // console.log('existingImages in Previews:', existingImages);
        const initialFiles = existingImages
            .filter((img) => img && img.url) // Filter out undefined or empty entries
            .map((img) => ({
                id: img.id,
                url: img.url,
                preview: img.url, // Use the image URL for preview if it's a valid image
            }));
        setFiles(initialFiles);
    }, [existingImages]);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#a6a5a5',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
        borderColor: '#596ffc'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const {
        getRootProps
        , getInputProps
        , isFocused
        , isDragAccept
        , isDragReject
    } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDrop: async (acceptedFiles) => {
            const uploadedFiles = await Promise.all(
                acceptedFiles.map(async (file) => {
                    const asset = await uploadImageToSanity(file);
                    if (asset) {
                        return {
                            id: asset.id,     // Use asset ID for Sanity references
                            url: asset.url,   // Use URL for preview
                            preview: URL.createObjectURL(file), // Preview from local file
                        };
                    }
                    return null; // Handle failed uploads
                })
            ).then(files => files.filter(Boolean)); // Filter out nulls if any upload fails
        
            setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
            onUpload(uploadedFiles); // Ensure `uploadedFiles` has the correct structure
        }
    });

    const handleRemoveImage = (imageId) => {
        if (!imageId) {
            console.error("Attempted to delete an image with an undefined ID");
        }
        // Remove the image from the local state
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== imageId));
        // Notify the parent component to update its state
        removeImage(imageId);
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.id}>
            <div style={thumbInner}>
                <img
                    src={file.url}
                    style={img}
                    alt="Preview"
                    onLoad={() => {
                        URL.revokeObjectURL(file.url);
                    }}
                />
            </div>
            <button
                style={removeButton}
                onClick={(e) => {
                    e.stopPropagation(); // Prevents the click from bubbling up
                    handleRemoveImage(file.id);
                }}
            >
                &times;
            </button>
        </div >
    ));

    useEffect(() => {
        // Clean up memory leaks by revoking preview URLs
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    return (
        <section className={styles.container}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Vous pouvez drag 'n' drop une image ici, or cliquez sur sélectionner une image</p>
            </div>
            {thumbs.length > 0 && (
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            )}
        </section>
    );
}

export default Previews;