// localSchema.js
import { Schema } from '@sanity/schema';

const localSchema = Schema.compile({
    name: 'localSchema',
    types: [
        {
            type: 'object',
            name: 'weddingVenue',
            fields: [
                {
                    name: 'description',
                    title: 'Description',
                    type: 'array',
                    of: [
                        {
                            type: 'block',
                            // You can also add custom styles, annotations, or even custom blocks here
                            styles: [
                                { title: 'Normal', value: 'normal' },
                                { title: 'H1', value: 'h1' },
                                { title: 'H2', value: 'h2' },
                                { title: 'Quote', value: 'blockquote' }
                            ],
                            lists: [{ title: 'Bullet', value: 'bullet' }],
                            marks: {
                                decorators: [
                                    { title: 'Strong', value: 'strong' },
                                    { title: 'Emphasis', value: 'em' },
                                ],
                                annotations: [
                                    {
                                        title: 'Link',
                                        name: 'link',
                                        type: 'object',
                                        fields: [
                                            {
                                                title: 'URL',
                                                name: 'href',
                                                type: 'url',
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ]
                },
            ],
        },
    ],
});

// Export the block content type for reuse
const blockContentType = localSchema.get('weddingVenue').fields.find((field) => field.name === 'description').type;

export default blockContentType;
