import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import PartnerPlanModal from '../components/PartnerPlanModal';
import VenueCreationSection from '../components/VenueCreationSection';
import LogoYG from '../assets/icons/Logo Bannière YG 2024 Biseauté Snow White.png';
import venueImg from '../assets/Images/Firefly Grand et bel hotel.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from '../PartnerRegistration.module.css';

const PartnerRegistration = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [activePlan, setActivePlan] = useState('yearly');  // Default to Yearly
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);   // Modal state

  // Handle the toggle between Yearly and Monthly plans
  const handlePlanSwitch = (plan) => {
    setActivePlan(plan);
  };

  const openModal = (plan, isAnnual) => {
    setSelectedPlan({ ...plan, isAnnual });  // Set the selected plan details
    setIsModalOpen(true);   // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);  // Close the modal
  };

  const createPartnerCheckoutSession = async (venueName, venueAddress, venueZipCode, venueCity, venueCountry, contactEmail, tier, vatNumber, subscriptionType, stripeAmount) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/partners/create-checkout-session`, {
        venueName,
        venueAddress,
        venueZipCode,
        venueCity,
        venueCountry,
        contactEmail, 
        tier,
        vatNumber,
        subscriptionType,
        stripeAmount,
        withCredentials: false,
      });
      
      if (response.data && response.data.id) {
        return { sessionId: response.data.id }; // Map to the expected format
      }
      
      throw new Error('Invalid response from server. Missing session ID.');
    } catch (error) {
      console.error('Erreur lors de la création d\'une session de checkout:', error);
      throw error;
    }
  };

  const handleSubmit = async (selectedPlan) => {
    try {

      const stripe = await stripePromise;
      const sessionData = await createPartnerCheckoutSession(
        selectedPlan.venueName,
        selectedPlan.venueAddress,
        selectedPlan.venueZipCode,
        selectedPlan.venueCity,
        selectedPlan.venueCountry,
        selectedPlan.contactEmail,
        selectedPlan.tier,
        selectedPlan.vatNumber,
        selectedPlan.subscriptionType,
        selectedPlan.price
      );

      const result = await stripe.redirectToCheckout({ sessionId: sessionData.sessionId });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la réservation', error);
      // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
    }
  };

  return (
    <div className={styles.partnerRegistrationPage}>
      <section className={styles.introSection}>
        <div className={styles.introText}>
          <img src={LogoYG} className={styles.introLogo} alt="Logo Your Guests" />
          <span>
            Chez Your Guests nous comprenons l’importance d’un mariage bien organisé, et nous sommes là pour vous aider à simplifier les réservations de chambres pour les invités de vos clients. <br />
            En devenant partenaire de Your Guests, vous aurez l’opportunité de vous mettre en avant auprès des futurs mariés et de leurs familles, tout en bénéficiant d’une plateforme  dédiée à la gestion des réservations.<br />
            <br />
          </span>
          <button
            className={styles.ctaButton}
            onClick={() => document.getElementById('plans-section').scrollIntoView()}
          >
            <FontAwesomeIcon icon={faArrowDown} style={{ color: "#ffffff" }} /> Voir comment créer votre lieu
          </button>
        </div>
        <div className={styles.introImage}>
          <img src={venueImg} alt="Wedding venue" />
        </div>
      </section>

      {/* Section 2: Plan Selection */}
      <section id="plans-section" className={styles.plansSection}>
      <VenueCreationSection />
      </section>
      
      <PartnerPlanModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleSubmit}
        selectedPlan={selectedPlan}
      />
    </div>

  );
};

export default PartnerRegistration;