import React, { useState, useEffect } from 'react';
import {
    fetchSanityVenueRules,
    fetchSanityVenuePMR,
    fetchSanityVenuePets,
    fetchSanityVenueSmoking,
    fetchSanityEquipments,
    fetchSanityEntertainment,
    fetchSanityBedSizes,
    fetchSanityFlags,
    fetchSanityParkingEquip,
    fetchSanityParkingType,
    fetchSanityParkingSecurity,
    fetchSanityTowelLinen,
    fetchSanityRentingItems, 
    fetchSanityRoomFlags, 
    fetchSanityRoomEquipment,
    fetchSanityBedSize
} from './VenueSanityHelpers';
import Select from 'react-select';
import '../../VenueDropdown.css';

const VenueDropdownMulti = ({ type, label, value, onChange }) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(
        (Array.isArray(value) ? value : []).map((item) => ({
            value: item.sizeType?._id || item._id || item.value,
            label: item.sizeType?.title || item.title || item.label,
            iconUrl: item.sizeType?.iconUrl || item.iconUrl,
            quantity: item.quantity || 1,
        }))
    );

    // Fetch data from Sanity based on the `type` prop
    useEffect(() => {
        const fetchData = async () => {
            try {
                let data;
                switch (type) {
                    case 'rules':
                        data = await fetchSanityVenueRules();
                        break;
                    case 'smoking':
                        data = await fetchSanityVenueSmoking();
                        break;
                    case 'PMR':
                        data = await fetchSanityVenuePMR();
                        break;
                    case 'pets':
                        data = await fetchSanityVenuePets();
                        break;
                    case 'equipment':
                        data = await fetchSanityEquipments();
                        break;
                    case 'entertainment':
                        data = await fetchSanityEntertainment();
                        break;
                    case 'bedsize':
                        data = await fetchSanityBedSizes();
                        break;
                    case 'flag':
                        data = await fetchSanityFlags();
                        break;
                    case 'parkingEquip':
                        data = await fetchSanityParkingEquip();
                        break;
                    case 'parkingType':
                        data = await fetchSanityParkingType();
                        break;
                    case 'parkingSecurity':
                        data = await fetchSanityParkingSecurity();
                        break;
                    case 'linenTowelDetails':
                        data = await fetchSanityTowelLinen();
                        break;
                    case 'itemType':
                        data = await fetchSanityRentingItems();
                        break;
                    case 'roomFlag':
                        data = await fetchSanityRoomFlags();
                        break
                    case 'roomEquipment':
                        data = await fetchSanityRoomEquipment();
                        break
                    case 'bedSize':
                        data = await fetchSanityBedSize();
                        break
                    default:
                        console.error(`Unknown type: ${type}`);
                        return;
                }

                const transformedOptions = data.map((option) => ({
                    value: option._id,
                    label: option.title,
                    iconUrl: option.iconUrl,
                }));
                setOptions(transformedOptions);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [type]);

    // console.log('value in dropdownMulti', value)
    // console.log('selectedOptions in dropdownMulti', selectedOptions)

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
        if (onChange) {
            if (type === "bedSize") {
                // For bedSize, map options with nested sizeType
                onChange(
                    selected.map((option) => ({
                        sizeType: {
                            _id: option.value,
                            title: option.label,
                            iconUrl: option.iconUrl,
                        },
                        quantity: option.quantity,
                    }))
                );
            } else {
                // For other types like roomEquipment, use a simpler structure
                onChange(
                    selected.map((option) => ({
                        _id: option.value,
                        title: option.label,
                        iconUrl: option.iconUrl,
                    }))
                );
            }
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#596ffc' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 1px #596ffc' : provided.boxShadow,
            '&:hover': {
                borderColor: '#596ffc',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#ccc',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f0f0f0',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#999',
            ':hover': {
                backgroundColor: '#e57373',
                color: 'white',
            },
        }),
    };

    return (
        <div className="dropdown-container">
            <label className='multidropdown-label'>{label}</label>
            <Select
                isMulti
                name="multi-options"
                options={options}
                value={selectedOptions}
                onChange={handleSelectChange}
                formatOptionLabel={(option) => (
                    <div className="dropdown-item-multi">
                        {option.iconUrl && (
                            <img
                                src={option.iconUrl}
                                alt={option.label || option.title}
                                className="dropdown-icon"
                            />
                        )}
                        <span>{option.label || option.title}</span>
                    </div>
                )}
                placeholder="Sélectionnez une ou plusieurs options..."
                classNamePrefix="react-select"
                noOptionsMessage={() => 'Aucune option disponible'}
                className='multidropdown-label'
                styles={customStyles}
            />
        </div>
    );
};

export default VenueDropdownMulti;