// Librairies
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
// import { AuthContext } from './contexts/AuthContext';
// import { useJwtInterceptor } from './_helpers/jwt.interceptor';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './Cookie.css';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd';
import { Helmet } from 'react-helmet';

// Composants
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import ResetPasswordForm from './components/ResetPasswordForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import MyCookieBanner from './components/CookieBanner';
import MyCustomizeBanner from './components/CustomizeBanner';

// Pages
import Homepage from './pages/Homepage';
import Creer from './pages/Creer';
import Contact from './pages/Contact';
import Error from './pages/Error';
import MonCompte from './pages/MonCompte';
import Register from './pages/register';
import DeposerFichierPage from './pages/DeposerFichier';
import ListeInvites from './pages/ListeInvites';
import VenueSelection from './pages/VenueSelection';
import CustomBooking from './pages/CustomBooking';
import EmailEditorPage from './pages/EmailEditor';
import Reservation from './pages/Reservation';
import StripeHome from './pages/StripeHome';
import DashboardPayments from './pages/DashboardPayments';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import BookedRoom from './pages/BookedRoom';
import PartnerPage from './pages/PartnerPage';
import PartnerRegistration from './pages/PartnerRegistration';
import ThanksSubscription from './pages/ThanksSubscription';
import ProPortal from './pages/ProPortal'

const App = () => {
  const [showCustomizeBanner, setShowCustomizeBanner] = useState(false);
  const currentDesignType = 'venue';
  
  return (
      <AuthProvider>
        <Router>
          <Helmet>
            <title>Your Guests</title>
            <meta name="description" content="Your Guests vous aide à organiser les réservations de chambres et couchages sur votre lieu de mariage" />
            <meta name="keywords" content="your guests,invités,mariage,réservation,réserver,mariage,dormir,your-guests,your,guests,lieu" />
            <meta name="author" content="Your Guests" />
            <meta charset="UTF-8"/>
          </Helmet>
          <Header />
          <MyCookieBanner setShowCustomizeBanner={setShowCustomizeBanner} />
          {showCustomizeBanner && <MyCustomizeBanner />}
          <Switch>
            <Route exact path="/" component={Homepage} />
            <PrivateRoute path="/creer/lieu-du-mariage/:projectId" component={VenueSelection} />
            <PrivateRoute path="/creer/depot-fichier/:projectId" component={DeposerFichierPage} />
            <PrivateRoute path="/creer/liste-d-invites/:projectId" component={ListeInvites} />
            <PrivateRoute path="/creer/personnaliser-reservation/:projectId" component={CustomBooking} />
            <PrivateRoute path="/creer" component={Creer} />
            <PrivateRoute path="/mon-compte" component={MonCompte} />
            <PrivateRoute path="/stripe-account-connect" component={StripeHome} />
            <PrivateRoute path="/dashboard/:projectId" component={DashboardPayments} />
            <Route path="/login-register" component={Register} />
            <Route path="/contact" component={Contact} />
            <Route path="/nos-partenaires" component={PartnerPage} />
            <Route path="/devenez-partenaire" component={PartnerRegistration} />
            <Route path="/partenaire/payment-completed/merci" component={ThanksSubscription} />
            <Route path="/reservations" component={Reservation} />
            <Route path="/booked-room" component={BookedRoom} />            
            <Route path="/forgot-password" component={ResetPasswordForm} />
            <Route path="/reset-password/" component={UpdatePasswordForm} />
            <Route path="/email-editor"
              render={(props) => <EmailEditorPage {...props} designType={currentDesignType} />}
            />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/pro/mon-lieu-de-mariage" component={ProPortal} />
            {/* <Route path="/oauth-success" component={OAuthSuccessHandler} /> */}
            <Route path="*" component={Error} />
          </Switch>
          <Footer />
        </Router>
      </AuthProvider>
  );
}

export default App;