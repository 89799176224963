import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, StandaloneSearchBox } from '@react-google-maps/api';
import useDebounce from './Debouncing/DebouncedSearchInput';
import sanClient from '../sanityClient';
import { urlFor } from './imageUrlBuilder';
import customMapStyles from './CustomMapStyles';
import weddingIcon from '../assets/icons/Marker_Events.png';
import NearbyPlaces from './GoogleMapsAPI/NearbyPlaces';
import RenderPlaces from './GoogleMapsAPI/RenderPlaces';
import { fetchLatLng } from './GoogleMapsAPI/FetchLatLng';
import styles from '../MapContainer.module.css'

const mapOptions = {
  styles: customMapStyles,
  mapTypeId: 'roadmap',
  disableDefaultUI: false,
  tilt: 0,
  gestureHandling: 'greedy'
};

const containerStyle = {
  width: '80%',
  height: '600px',
  margin: '0 auto',
};

const center = {
  lat: 48.866667,
  lng: 2.333333,
};

const libraries = ['places'];

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function WeddingMapComp({ handleVenueSelect, handleVenueNotFound, openModal }) {
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [places, setPlaces] = useState(null);
  const [venues, setVenues] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const handleSelect = async (venue) => {
    // Fetch nearby places if they have been loaded
    const hotelsAround = places ? places.map((place) => ({
      name: place.name,
      address: place.vicinity,
      rating: place.rating,
      latitude: place.geometry.location.lat,
      longitude: place.geometry.location.lng,
      website: place.cwebsite || '',  // Add website from place details if available
      phone: place.details?.formatted_phone_number,
      description: place.keyword,  // Use keyword as description
    })) : [];
    // console.log('hotelsAround', hotelsAround)

    const minimalVenueData = {
      _id: venue._id,
      name: venue.name,
      address: venue.address,
      latitude: venue.latitude,
      longitude: venue.longitude,
      logo: venue.logo,
      website: venue.website,
      maxCapacity: venue.maxCapacity,
      description: venue.description,
      gallery: venue.gallerie
    };

    // Combine venue info from Sanity with nearby places
    const venueWithHotels = {
      ...minimalVenueData,  // Sanity venue data
      hotelsAround,  // Add nearby places (hotels, BnBs, etc.)
    };
    console.log('venueWithHotels:', venueWithHotels);
    setSelectedVenue(venueWithHotels);  // Update selected venue with hotels
    handleVenueSelect(venueWithHotels); // Pass the venue with nearby hotels to the parent
    openModal();
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onSearchBoxLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = async () => {
    if (!searchInput) return;

    const coordinates = await fetchLatLng(searchInput);
    if (coordinates) {
      const { lat, lng } = coordinates;
      mapRef.current.panTo({ lat, lng });
      handleShowLocation({ lat, lng });
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);  // Update search input on each keystroke
  };

  // Trigger search when the debounced search term changes
  useEffect(() => {
    if (debouncedSearchTerm) {
      // Perform search when debounced value updates
      onPlacesChanged();
    }
  }, [debouncedSearchTerm]);

  const fetchVenuesFromSanity = async (lat, lng) => {
    try {
      const query = `*[_type == "weddingVenue" && latitude == $lat && longitude == $lng]`;
      const sanityResponse = await sanClient.fetch(query, { lat, lng });

      if (sanityResponse.length > 0) {
        setVenues(sanityResponse);
        return sanityResponse[0];
      } else {
        handleVenueNotFound();
        return null;
      }
    } catch (error) {
      console.error('Error fetching data from Sanity:', error);
    }
  };

  const handleShowLocation = async ({ lat, lng }) => {

    const venue = await fetchVenuesFromSanity(lat, lng);
    if (venue) {
      setSelectedVenue(venue); // Update selectedVenue if a venue is found
    }
  };

  const handleFetchPlaces = (fetchedPlaces) => {
    setPlaces(fetchedPlaces);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <>
      <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
        <input
          type="text"
          placeholder="Rechercher un lieu de mariage..."
          value={searchInput}
          onChange={handleSearchInputChange}
          style={{
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '300px',
            height: '40px',
            marginTop: '10px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '5',
          }}
        />
      </StandaloneSearchBox>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onMapLoad}
        options={mapOptions}
      >
        {venues.map((venue, index) => (
          <Marker
            key={index}
            position={{ lat: venue.latitude, lng: venue.longitude }}
            icon={{
              url: weddingIcon,
              scaledSize: new window.google.maps.Size(50, 50),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 50),
            }}
            onClick={() => handleSelect(venue)}
          >
            {selectedVenue && selectedVenue._id === venue._id && (
              <InfoWindow
                position={{ lat: venue.latitude, lng: venue.longitude }}
                onCloseClick={() => setSelectedVenue(null)}
              >
                <div style={{ maxWidth: '500px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    <h3 style={{ margin: 0, fontWeight: 'bold', fontSize: '16px' }}>{venue.name}</h3>
                  </div>
                  <img src={urlFor(venue.logo).auto('format').width(150).url()} alt={venue.name} style={{ width: '100%', height: 'auto', marginBottom: '8px' }} />
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    <button
                      onClick={() => handleSelect(venue)}
                      className={styles.buttonS}
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {selectedVenue && selectedVenue.latitude && selectedVenue.longitude && (
          <NearbyPlaces
            location={{ lat: selectedVenue.latitude, lng: selectedVenue.longitude }}
            keywords={['hotel', 'guest house', 'bed and breakfast', 'chalet', 'bnb', 'hostel']}
            apiKey={apiKey}
            onFetchPlaces={handleFetchPlaces}
          />
        )}
        <RenderPlaces places={places} />
      </GoogleMap>
    </>
  );
}

export default WeddingMapComp;