import sanClient from '../sanityClient';
import { getPublicVenueID, getPublicLatLngProjectVenue } from '../api/api';

export const fetchVenueMedia = async (userId, projectId) => {
  const query = `*[_type == "weddingVenue" && _id == $venueId]{
      virtualTourUrl,
      gallerie[]{
        asset->{
          _id,
          url
        },
        alt
      }
    }`;
  try {
    const venueData = await getPublicVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'url visite tour dans Sanity:', error);
    throw error;
  }
};

export const fetchBuildingsRooms = async (userId, projectId) => {
  const query = `*[_type == "building" && weddingVenue._ref == $venueId]{
      _id,
      buildingName,
      totalBeds,
      rooms[]{
        _key,
        name,
        beds,
        "flags": flags[]->{
          title,
          value,
          _id,
          "iconUrl": icon.asset->url
        },
        groomRoom,
        floor,
        "bedSize": bedSize[]{
          "sizeType": sizeType->{
            title,
            _id,
            "iconUrl": icon.asset->url
          },
          quantity
        },
        "equipment": equipment[]->{
          title,
          _id,
          "iconUrl": icon.asset->url
        }
      },
      suites[]{
        _key,
        suiteName,
        totalSuiteBeds,
        "suiteFlags": suiteFlags[]->{
          title,
          value,
          _id,
          "iconUrl": icon.asset->url
        },
        roomsInSuite[]{
          _key,
          name,
          beds,
          "flags": flags[]->{
            title,
            value,
            _id,
            "iconUrl": icon.asset->url
          },
          groomRoom,
          floor,
          "bedSize": bedSize[]{
            "sizeType": sizeType->{
              title,
              _id,
              "iconUrl": icon.asset->url
            },
            quantity
          },
          "equipment": equipment[]->{
            title,
            _id,
            "iconUrl": icon.asset->url
          }
        }
      },
      images
    }`;
  try {
    const venueData = await getPublicVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};

export const fetchRoomDetails = async (roomKey) => {
  console.log('roomId dans fetchRoomDetails', roomKey);
  const query = `*[_type == "building" && defined(coalesce(
        rooms[_key == $roomKey][0],
        suites[].roomsInSuite[_key == $roomKey][0]
      ))] {
        _id,
        buildingName,
        rooms[_key == $roomKey]{
          _key,
          name,
          beds,
          "flags": flags[]->{
            title,
            value,
            _id,
            "iconUrl": icon.asset->url
          },
          groomRoom,
          floor,
          "bedSize": bedSize[]{
            "sizeType": sizeType->{
              title,
              _id,
              "iconUrl": icon.asset->url
            },
            quantity
          },
          "equipment": equipment[]->{
            title,
            _id,
            "iconUrl": icon.asset->url
          },
          "roomImgUrls": images[].asset->url
        },
        suites[_key == $roomKey]{
          _key,
          suiteName,
          totalSuiteBeds,
          "suiteFlags": suiteFlags[]->{
            title,
            value,
            _id,
            "iconUrl": icon.asset->url
          },
          roomsInSuite[]{
            _key,
            name,
            beds,
            "flags": flags[]->{
              title,
              value,
              _id,
              "iconUrl": icon.asset->url
            },
            groomRoom,
            floor,
            "bedSize": bedSize[]{
              "sizeType": sizeType->{
                title,
                _id,
                "iconUrl": icon.asset->url
              },
              quantity
            },
            "equipment": equipment[]->{
              title,
              _id,
              "iconUrl": icon.asset->url
            }
          },
          "roomImgUrls": images[].asset->url
        },
        "buildingImgUrls": images[].asset->url
      }
  `;
  try {
    const SanityData = await sanClient.fetch(query, { roomKey });
    return SanityData // Filter out buildings where rooms or suites are empty
  } catch (error) {
    console.error('Erreur lors de la requête des détails de la chambre dans Sanity:', error);
    throw error;
  }
};

export const fetchRoomsAndFlags = async (userId, projectId) => {
  // Requête GROQ pour récupérer les chambres et les flags
  const query = `*[_type == "building" && weddingVenue._ref == $venueId] {
      _id,
      buildingName,
      rooms[!defined(isInSuite) || isInSuite == false] {
        "flagValues": flags[]->value,
      }
    }`;
  try {
    const venueData = await getPublicVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};

export const processRoomsByFlag = (buildings) => {
  const buildingsRoomsByFlag = buildings.map(building => {
    // Initialisation de l'objet de comptage pour le bâtiment courant
    const roomsByFlag = {
      couple: 0,
      family: 0,
      largeFamily: 0,
      single: 0
    };

    // Itération sur chaque chambre du bâtiment
    building.rooms.forEach(room => {
      // Itération sur chaque flag de la chambre
      room.flagValues.forEach(flagValue => {
        // Incrémentation du compteur correspondant au flag
        if (flagValue === 'couple') roomsByFlag.couple++;
        if (flagValue === 'family') roomsByFlag.family++;
        if (flagValue === 'largeFamily') roomsByFlag.largeFamily++;
        if (flagValue === 'single') roomsByFlag.single++;
      });
    });

    // Retourne un objet contenant le nom du bâtiment et le comptage des chambres par flag
    return {
      buildingName: building.buildingName,
      roomsByFlag
    };
  });

  // Retourne le tableau des résultats pour tous les bâtiments
  return buildingsRoomsByFlag;
};

export const fetchSuitesAndFlags = async (userId, projectId) => {
  // Requête GROQ similaire à fetchRoomsAndFlags adaptée aux suites
  const query = `*[_type == "building" && weddingVenue._ref == $venueId] {
      _id,
      buildingName,
      suites[] {
        "flagValues": suiteFlags[]->value
      }
    }    
    `
  try {
    const venueData = await getPublicVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};

export const processSuitesByFlag = (buildings) => {
  const buildingsSuitesByFlag = buildings.map(building => {
    // Initialisation de l'objet de comptage pour le bâtiment courant
    const suitesByFlag = {
      couple: 0,
      family: 0,
      largeFamily: 0,
      single: 0
    };

    // Itération sur chaque chambre du bâtiment
    building.rooms.forEach(room => {
      // Itération sur chaque flag de la chambre
      room.flagValues.forEach(flagValue => {
        // Incrémentation du compteur correspondant au flag
        if (flagValue === 'couple') suitesByFlag.couple++;
        if (flagValue === 'family') suitesByFlag.family++;
        if (flagValue === 'largeFamily') suitesByFlag.largeFamily++;
        if (flagValue === 'single') suitesByFlag.single++;
      });
    });

    // Retourne un objet contenant le nom du bâtiment et le comptage des chambres par flag
    return {
      buildingName: building.buildingName,
      suitesByFlag
    }; 
  });

  // Retourne le tableau des résultats pour tous les bâtiments
  return buildingsSuitesByFlag;
};

export const fetchSanityVenueInfo = async (userId, projectId) => {
  const query = `*[_type == "weddingVenue" && latitude == $latitude && longitude == $longitude]{
      name,
      address,
      website,
      description,
      latitude,
      longitude
  }`;
  try {
    const venueLatLng = await getPublicLatLngProjectVenue(userId, projectId);
    const latitude = venueLatLng.data.venue.latitude;
    const longitude = venueLatLng.data.venue.longitude;

    const data = await sanClient.fetch(query, { latitude, longitude });
    return data;
  } catch (error) {
    console.error('Erreur lors de la requête Sanity:', error);
    throw error;
  }
};

export const fetchVenueEquipments = async (userId, projectId) => {
  const query = `*[_type == "weddingVenue" && _id == $venueId] {
    "Entertainment": additionalFacilities.entertainment[]-> {
      title,
      _id,
      "iconUrl": icon.asset->url
    },
    "Parking": {
      "TotalSpaces": additionalFacilities.parking.totalSpaces,
      "Equipment": additionalFacilities.parking.Equipement[]-> {
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      "ParkingType": additionalFacilities.parking.parkingType[]->{
        title,
        "iconUrl": icon.asset->url
        },
      "Security": additionalFacilities.parking.Security[]-> {
        title,
        _id,
        "iconUrl": icon.asset->url
      }
    },
    "Wifi": additionalFacilities.Wifi,
    "LinenDetails": {
      "Provided": additionalFacilities.linenDetails.provided[]-> {
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      "AdditionalCost": additionalFacilities.linenDetails.additionalCost
    },
    "TowelDetails": {
      "Provided": additionalFacilities.towelDetails.provided[]-> {
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      "AdditionalCost": additionalFacilities.towelDetails.additionalCost
    },
    "petPolicies": petPolicies[]->{
      title,
      value,
      "iconUrl": icon.asset->url
    },
    "SmokingPolicy": smokingPolicy[]-> {
      title,
      value,
      "iconUrl": icon.asset->url
    },
    "disabledAccessOptions": disabledAccessOptions[]->{
      title,
      value,
      "iconUrl": icon.asset->url
    },
    "park": park
  }
  `
  try {
    const venueData = await getPublicVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête Sanity:', error);
    throw error;
  }
}