// utils.js or within VenueDropdown.js
export const floorMapping = {
    "rdc": { value: "rdc", label: "RDC", iconUrl: "" },
    "1er": { value: "1er", label: "1er étage", iconUrl: "" },
    "2e": { value: "2e", label: "2e étage", iconUrl: "" },
    "3e": { value: "3e", label: "3e étage", iconUrl: "" },
    "4e": { value: "4e", label: "4e étage", iconUrl: "" },
    "5e": { value: "5e", label: "5e étage", iconUrl: "" },
};

export const matchFloorData = (floorString) => {
    return floorMapping[floorString] || null;
};
