import axios from 'axios';

export const fetchLatLng = async (address) => {
    const url = `${process.env.REACT_APP_API_URL}/public/api/get-lat-lng?address=${address}`;

    try {
        const response = await axios.get(url);
        const { lat, lng } = response.data; // Use only lat and lng from the response
        return { lat, lng };
    } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
    }    
};