import React, { useState, useEffect} from 'react';
import { GiCastle, GiHouse } from "react-icons/gi";
import { LuPanelLeftClose, LuPanelLeftOpen } from 'react-icons/lu';
import { FaChevronDown, FaChevronRight, FaPlus } from 'react-icons/fa';
import { useVenueContext } from '../../contexts/VenuesContext';
import LogoYG from '../../assets/icons/Logo YG 2024 Biseauté Snow White.png'
import styles from '../../ProPortal.module.css';

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [expandedSections, setExpandedSections] = useState({ venue: true, building: false });
    const { venues, activeVenue, setActiveVenue, activeBuilding, setActiveBuilding, createEmptyVenueForm, createEmptyBuildingForm } = useVenueContext();
    const [isNearFooter, setIsNearFooter] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('footer');
            const logoYG = document.querySelector(`.${styles.logoContainer}`); // Using CSS module class here

            if (!footer || !logoYG) {
                return;
            }

            const rect = footer.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.top < windowHeight) {
                setIsNearFooter(true);
                logoYG.style.bottom = `${rect.height + 5}px`;
            } else {
                setIsNearFooter(false);
                logoYG.style.bottom = '2svw'; 
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 768;
            setIsSmallScreen(isSmall);
            if (isSmall) setCollapsed(true);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => setCollapsed(!collapsed);
    

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));

        if (section === 'building' && (!activeVenue?.buildings || activeVenue.buildings.length === 0)) {
            setActiveBuilding(null); // Clear activeBuilding if no buildings exist
        }

        if (!isSmallScreen || !collapsed) {
            // Only allow toggling sections if not collapsed
            setActiveVenue(null); // Clear activeVenue
        }
    };


    return (
        <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''} ${isSmallScreen ? styles.smallScreen : ''}`}>
            <button
                className={`${styles.toggleButton} ${collapsed ? styles.toggleButtonCollapse : ''}`}
                onClick={toggleSidebar}
            >
                {collapsed ? <LuPanelLeftOpen className={styles.iconPanel} /> : <LuPanelLeftClose className={styles.iconPanel} />}
            </button>

            {!collapsed && (
                <>

            {/* Venue Section */}
            <div className={styles.sidebarSection}>
                <button onClick={() => toggleSection('venue')} className={styles.sidebarButton}>
                    {!collapsed ? 'Lieu de mariage' : <GiCastle className={styles.iconPanel} />}
                    {expandedSections.venue ? <FaChevronDown className={styles.iconChevPlus} /> : <FaChevronRight className={styles.iconChevPlus} />}
                </button>

                <button onClick={() => createEmptyVenueForm()} className={styles.sidebarButton}>
                    <FaPlus />
                </button>
            </div>
            {expandedSections.venue && venues.length > 0 && (
                <ul className={styles.itemList}>
                    {venues.map((venue) => (
                        <li
                            key={venue._id}
                            onClick={() => {
                                setActiveVenue(prevVenue => ({
                                    ...venue,
                                    buildings: prevVenue._id === venue._id && prevVenue.buildings ? prevVenue.buildings : venue.buildings || [],
                                }));
                                setActiveBuilding(null); //Reset activeBuilding to show VenueForm
                                // Ensure the buildings section stays open if it was already expanded
                                setExpandedSections((prev) => ({ ...prev, building: true }));
                            }}
                            className={activeVenue?._id === venue._id ? styles.itemActive : styles.item}
                        >
                            {venue.name}
                        </li>
                    ))}
                </ul>
            )}

            {/* Buildings Section */}
            <div className={styles.sidebarSection}>
                <button onClick={() => toggleSection('building')} className={styles.sidebarButton}>
                    {!collapsed ? 'Bâtiments' : <GiHouse className={styles.iconPanel} />}
                    {expandedSections.building ? <FaChevronDown className={styles.iconChevPlus} /> : <FaChevronRight className={styles.iconChevPlus} />}
                </button>

                <button onClick={() => createEmptyBuildingForm()} className={styles.sidebarButton}>
                    <FaPlus />
                </button>
            </div>
            <>
                {activeVenue?.buildings && activeVenue.buildings.length > 0 ? (
                    <ul className={styles.itemList}>
                        {activeVenue.buildings.map((building) => (
                            <li
                                key={building._id}
                                onClick={() => setActiveBuilding(building)}
                                className={activeBuilding?._id === building._id ? styles.itemActive : styles.item}
                            >
                                {building.buildingName || 'Bâtiment sans nom'}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className={styles.noDataMessage}>
                        Aucun bâtiment trouvé. Cliquez sur "+" pour en créer un.
                    </p>
                )}
            </>
            <div className={styles.logoContainer}>
                <img
                    src={LogoYG}
                    alt="Logo"
                    className={styles.logo}
                />
            </div>
            </>
            )}
        </div>
    );
};

export default Sidebar;
