import React from 'react';
import styles from '../../NotProPage.module.css'

const NotProPage = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>⛔️Accès refusé⛔️</h1>
            <p>Votre compte n'est pas configuré comme professionnel. Veuillez <a className={styles.hrefColor} href="/contact">contacter</a> le support pour plus d'informations.</p>
        </div>
    );
};

export default NotProPage;