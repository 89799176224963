import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import VenueDropdownMulti from './VenueDropDownMulti';
import VenueDropdown from './VenueDropdown';
import styles from '../../SelectModal.module.css';
import '../../VenueDropdown.css';

const SelectVenueModalObject = ({ rentableItemData, label, onSave, onCancel }) => {
    const [selectedItem, setSelectedItem] = useState({
        itemType: { value: '', label: '', iconUrl: '' },
        quantity: '',
        price: '',
        linenTowelDetails: [],
    });

    useEffect(() => {
        if (rentableItemData) {
            const itemTypeData = Array.isArray(rentableItemData.itemType)
                ? rentableItemData.itemType[0]
                : rentableItemData.itemType || {};

            setSelectedItem({
                itemType: {
                    value: itemTypeData?.value || '', // Assuming the value could be present
                    label: itemTypeData?.title || '', // Extracts title as label
                    iconUrl: itemTypeData?.iconUrl || '',
                },
                quantity: rentableItemData.quantity || '',
                price: rentableItemData.price || 0,
                linenTowelDetails: rentableItemData.linenProvided || [],
            });
        }
    }, [rentableItemData]);

    useEffect(() => {
        console.log('selectedItem:', selectedItem);
    }, [selectedItem]);

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            overflow: 'auto',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
        },
    });
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%',
                    },
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%',
                    },
                }));
            } else {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%',
                    },
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSave = () => {
        const flattenedItem = {
            itemType: selectedItem.itemType,
            quantity: parseInt(selectedItem.quantity, 10) || 0,
            price: parseFloat(selectedItem.price) || 0,
            linenTowelDetails: selectedItem.linenTowelDetails,
        };
        onSave(flattenedItem);
    };

    return (
        <Modal isOpen={true} onRequestClose={onCancel} contentLabel={label} style={modalStyle}>
            <h2 className={styles.modalTitle}>{label}</h2>

            <VenueDropdown
                type="itemType"
                label="Type d'article"
                value={selectedItem.itemType}
                onChange={(selectedOption) => {
                    setSelectedItem({ ...selectedItem, itemType: selectedOption });
                }}
            />

            <label>Quantité</label>
            <input
                type="number"
                name="quantity"
                label="Quantité"
                value={selectedItem.quantity}
                onChange={(e) => setSelectedItem({ ...selectedItem, quantity: parseInt(e.target.value, 10) || '' })}
                className={styles.customModalInput}
            />

            <label>Prix</label>
            <input
                type="number"
                name="price"
                label="Prix de Location"
                placeholder="Indiquez 0 si offert."
                value={selectedItem.price}
                onChange={(e) => setSelectedItem({ ...selectedItem, price: parseFloat(e.target.value) || '' })}
                className={styles.customModalInput}
            />

            <VenueDropdownMulti
                type="linenTowelDetails"
                name="linenTowelDetailsModal"
                label="Indiquez quels linges sont fournis"
                value={selectedItem.linenTowelDetails}
                onChange={(selectedOptions) => {
                    setSelectedItem({ ...selectedItem, linenTowelDetails: selectedOptions });
                }}
            />

            <div className={styles.modalButtons}>
                <button className={styles.cancelButton} onClick={onCancel}>
                    Annuler
                </button>
                <button className={styles.saveButton} onClick={handleSave}>
                    Confirmer
                </button>
            </div>
        </Modal>
    );
};

export default SelectVenueModalObject;
