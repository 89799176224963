import React from 'react';
import { VenueContextProvider, useVenueContext } from '../contexts/VenuesContext';
import VenueForm from '../components/ProPortal/VenueForm';
import BuildingForm from '../components/ProPortal/BuildingForm';
import NoDataFallback from '../components/ProPortal/NoDataFallback';
import Sidebar from '../components/ProPortal/Sidebar';
import NotProPage from '../components/ProPortal/NotProPage';
import { Circles } from 'react-loader-spinner';
import styles from '../ProPortal.module.css'

const ProPortalContent = () => {
    const { isLoading, activeVenue, activeBuilding, createEmptyVenueForm, isProfessional } = useVenueContext();

    if (isLoading) {
        return (
            <div className={styles.loadingContainer}>
                <Circles visible={true} height="80" width="80" ariaLabel="color-loading" wrapperStyle={{}} wrapperClass="" color="#5465ff" />
            </div>
        );
    }
    
    if (!isProfessional) {
        return <NotProPage />; // Render the "Accès refusé" component
    }

    const noData = !activeVenue && !activeBuilding;

    return (
        <div className={styles.PageContainer}>
            <div className={styles.background} data-speed="2"></div>
            <Sidebar />
            <div className={styles.contentContainer}>
                {noData && (
                    <NoDataFallback
                        message="Aucun lieu trouvé. Créez votre premier lieu de mariage !"
                        onAction={createEmptyVenueForm}
                        actionLabel="Créer un Lieu"
                    />
                )}
                {activeVenue && !activeBuilding && <VenueForm />}
                {activeBuilding && <BuildingForm />}
            </div>
        </div>
    );
};

const ProPortal = () => (
    <VenueContextProvider>
        <ProPortalContent />
    </VenueContextProvider>
);

export default ProPortal;