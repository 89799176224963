import { createClient } from '@sanity/client'

const sanClient = createClient({
  projectId: 'cswj8235', // Vous trouverez ceci dans manage.sanity.io ou dans votre fichier sanity.json
  dataset: 'production', // ou le nom de votre dataset
  apiVersion: '2024-10-17', // Utilisez une date actuelle pour obtenir les dernières fonctionnalités // Laissez vide pour un accès en lecture seule, ou utilisez un token pour les requêtes en écriture
  useCdn: true, // `false` si vous voulez assurer une fraîcheur des données à 100%
  token: 'sklDq8OxBerP5JG9ej4nsrmdVkpJkni5Mt8UPQeJnb8Qx5VOAJOkmeBMUW3TMLYPDbEIOpUU1xTDCmUtjY7nMgHxCjxt284YdQuUHrXmUrNecfygpxGYrc169a8rCrbBreKP7ngC7h8l5UyA3mQ0A4RUPpwM2MXoWEijykEQHsX7SzEHw4tp'
})

export default sanClient;