import React, { useState, useEffect } from 'react';
import { useVenueContext } from '../../contexts/VenuesContext';
// import { getVenueByUserId, createOrUpdateVenue } from '../api/sanityApi'; // Custom API for Sanity
// import { useHistory } from 'react-router-dom'
import Previews from './DnDImgPreview';
import SelectRoomModal from './SelectRoomModal';
import SelectSuiteModal from './SelectSuiteModal';
import DeleteModal from './DeleteModal';
import { FaTrash, FaTrashAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from '../../VenueForm.module.css';

const BuildingForm = () => {
    const [noData, setNoData] = useState(true);
    const [isRoomModalOpen, setRoomModalOpen] = useState(false);
    const [isSuiteModalOpen, setSuiteModalOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isNearFooter, setIsNearFooter] = useState(false);
    const [selectedSuite, setSelectedSuite] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const { activeBuilding, setActiveBuilding, activeVenue, addBuilding, deleteBuilding, userId, createEmptyBuildingForm } = useVenueContext();
    const [formValues, setFormValues] = useState(activeBuilding || {});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setFormValues({
            ...activeBuilding,
            rooms: activeBuilding?.rooms || [],
            suites: activeBuilding?.suites || [],
            images: activeBuilding?.images || []
        });
    }, [activeBuilding]);

    useEffect(() => {
        if (!activeBuilding || Object.keys(activeBuilding).length === 0) {
            setNoData(true);
            // const defaultBuilding = createEmptyBuildingForm;
            // setActiveBuilding(defaultBuilding);
            // setFormValues(defaultBuilding);
        } else {
            setNoData(false);
            setFormValues(activeBuilding);
        }
    }, [activeBuilding, userId]);

    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('footer');
            const saveButton = document.querySelector(`.${styles.buttonSave}`); // Using CSS module class here
            const deleteButton = document.querySelector(`.${styles.buttonDelete}`);

            if (!footer || !saveButton || !deleteButton) {
                // If either footer or saveButton is not found, exit early
                return;
            }

            const rect = footer.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Check if the footer is coming into view
            if (rect.top < windowHeight) {
                setIsNearFooter(true);
                // Adjust button position based on footer's distance from bottom
                saveButton.style.bottom = `${rect.height + 50}px`; // 20px padding above the footer
                deleteButton.style.bottom = `${rect.height + 50}px`;
            } else {
                setIsNearFooter(false);
                saveButton.style.bottom = '2svw'; // Default bottom spacing
                deleteButton.style.bottom = '2svw';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // const history = useHistory();


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: isNaN(value) ? value : parseFloat(value) || 0,
        }));
        setHasChanges(true)
    };

    const handleSaveModalRoom = (updatedRoom) => {
        console.log('updatedRoom', updatedRoom)
        setFormValues((prevValues) => {
            const updatedRooms = selectedRoom?.index !== null
                ? prevValues.rooms.map((room, i) => i === selectedRoom.index ? updatedRoom : room)
                : [...prevValues.rooms, updatedRoom];

            return {
                ...prevValues,
                rooms: updatedRooms,
            };
        });
    
        setRoomModalOpen(false);
    };

    const removeRoom = (indexToRemove) => {
        setFormValues((prev) => ({
            ...prev,
            rooms: prev.rooms.filter((_, index) => index !== indexToRemove),
        }));
        setHasChanges(true)
    };

    const handleSaveModalSuite = (updatedSuite) => {
        setFormValues((prevValues) => {
            const updatedSuites = selectedSuite?.index !== null
                ? prevValues.suites.map((suite, i) => i === selectedSuite.index ? updatedSuite : suite)
                : [...prevValues.suites, updatedSuite];

            return {
                ...prevValues,
                suites: updatedSuites,
            };
        });
    
        setSuiteModalOpen(false);
    };

    const removeSuite = (indexToRemove) => {
        setFormValues((prev) => ({
            ...prev,
            suites: prev.suites.filter((_, index) => index !== indexToRemove),
        }));
        setHasChanges(true)
    };

    const handleGalleryUpload = (uploadedFiles) => {
        setFormValues((prev) => ({
            ...prev,
            images: [
                ...(prev.images || []),
                ...uploadedFiles.map((file) => ({
                    asset: { _ref: file.id, url: file.url } // Consistent structure
                }))
            ],
        }));
    };

    const handleRemoveImageFromGallery = (imageId) => {
        if (!imageId) {
            console.error("Image ID is undefined in handleRemoveImageFromGallery");
            return;
        }
        console.log('formValues.images before set', formValues.images)
        setFormValues((prevData) => {
            const updatedImages = prevData.images?.filter(
                (file) => file.asset?._ref !== imageId && file.asset?._id !== imageId
            );
            return {
                ...prevData,
                images: updatedImages || [],
            };
        });
        console.log('formValues.images after set', formValues.images)

        setHasChanges(true)
    };

    const handleSave = async () => {
        try {
            // console.log('buildingData in handleSave', buildingData);
            setActiveBuilding(formValues)
            await addBuilding(formValues.weddingVenue, formValues);
            alert('Building information saved successfully!');
            setHasChanges(false)
        } catch (error) {
            console.error('Error saving venue:', error);
            alert('An error occurred while saving the venue information.');
        }
        
    };
    
    const handleDeleteBuilding = async () => {
        if (activeBuilding?._id) {
            await deleteBuilding(activeBuilding._id, activeVenue?._id); // Call delete function from context
        }
        setShowDeleteModal(false); // Close the modal
    };

    const openRoomModal = (index = null) => {
        setSelectedRoom(
            index !== null
                ? { ...formValues.rooms[index], index } // Add the index to the selectedRoom object for editing
                : { name: '',
                    beds: '',
                    flags: [],
                    groomRoom: false,
                    isInSuite: false,
                    floor: '',
                    bedSize: [],
                    equipment: [],
                    images: [],
                    index: null 
                } // Include index: null for new rooms
        );
        setRoomModalOpen(true);
    };
    
    const closeRoomModal = () => {
        setRoomModalOpen(false);
    };
    
    const openSuiteModal = (index = null) => {
        setSelectedSuite(
            index !== null 
                ? { ...formValues.suites[index], index }
                : { suiteName: '', totalSuiteBeds: '', index: null });
        setSuiteModalOpen(true);
    };

    const closeSuiteModal = () => {
        setSuiteModalOpen(false);
    };

    return (
        <div className={styles.venueFormContainer}>
            {noData ? (
                <div className={styles.noDataContainer}>
                    <p>Aucun bâtiment trouvé. Créez votre premier bâtiment présent sur votre lieu de mariage !</p>
                    <button onClick={createEmptyBuildingForm}>
                        Créer un Bâtiment
                    </button>
                </div>
            ) : (
                <>
                    <h1 className={styles.venueFormTitle}>
                        {formValues?.buildingName || 'Bâtiment'}
                    </h1>
                    <h3 className={styles.venueFormSubTitle}>
                        {formValues?.venueName}
                    </h3>
                    <form className={styles.venueForm}>
                        <label>Nom du bâtiment</label>
                        <input type="text" name="buildingName" value={formValues?.buildingName} onChange={handleInputChange} required />

                        <label className='mt-2'>Nombre de couchage</label>
                        <input type="number" name="totalBeds" value={formValues?.totalBeds} onChange={handleInputChange} placeholder='La capacité maximale du lieu de mariage en termes de nombre de personnes.' />

                        <label>Ajouter des chambres</label>
                        <div className={styles.venueBuildingRoom}>
                            <div className={styles.selectedItemsContainer}>
                                {formValues.rooms.length > 0 ? (
                                    formValues.rooms.map((room, index) => (
                                        <div
                                            key={index}
                                            className={styles.selectedItem}
                                            onClick={() => openRoomModal(index)}
                                        >
                                            <div className={styles.itemDetails}>
                                                <span className={styles.selectedSpan}>{room.name}</span>
                                                <span className={styles.selectedSpan}>| Couchages: {room.beds}</span>
                                                <span className={styles.selectedSpan}>| Étage: {room.floor?.label || room.floor}</span>
                                                <div className={styles.iconContainer}>
                                                    <div className={styles.verticalLine}></div>
                                                    <button
                                                        className={styles.deleteButton}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevents form submission
                                                            e.stopPropagation();
                                                            removeRoom(index);
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.noItemsText}>Aucune chambre encore créée</p>
                                )}
                            </div>
                            <button type="button" className={styles.openModalButton} onClick={() => openRoomModal()}>
                                <FontAwesomeIcon icon={faPlus} className={styles.chevronIcon} /> Ajoutez une chambre
                            </button>

                            {isRoomModalOpen && (
                                <SelectRoomModal
                                    roomData={selectedRoom}
                                    onSave={handleSaveModalRoom}
                                    onCancel={closeRoomModal}
                                />
                            )}
                        </div>

                        <div className={styles.venueBuildingRoom}>
                            <div className={styles.selectedItemsContainer}>
                                {formValues.suites?.length > 0 ? (
                                    formValues.suites.map((suite, index) => (
                                        <div
                                            key={index}
                                            className={styles.selectedItem}
                                            onClick={() => openSuiteModal(index)} // Make the entire item clickable
                                        >
                                            <div className={styles.itemDetails}>
                                                <span className={styles.selectedSpan}>{suite.suiteName}</span>
                                                <span className={styles.selectedSpan}>| Couchages: {suite.totalSuiteBeds}</span>
                                                <div className={styles.iconContainer}>
                                                    <div className={styles.verticalLine}></div>
                                                    <button
                                                        className={styles.deleteButton}
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevents form submission
                                                            e.stopPropagation(); // Prevent the click from propagating to the parent div
                                                            removeSuite(index);
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.noItemsText}>Aucune suite encore créée</p>
                                )}
                            </div>
                            <button type="button" className={styles.openModalButton} onClick={() => openSuiteModal()}>
                                <FontAwesomeIcon icon={faPlus} className={styles.chevronIcon} /> Ajoutez une suite
                            </button>

                            {isSuiteModalOpen && (
                                <SelectSuiteModal
                                    suiteData={selectedSuite} // Pass the selected suite data
                                    handleInputChange={handleInputChange}
                                    onSave={handleSaveModalSuite}
                                    onCancel={closeSuiteModal}
                                />
                            )}
                        </div>

                        <label>Galerie</label>
                        <Previews
                            onUpload={handleGalleryUpload}
                            removeImage={handleRemoveImageFromGallery}
                            existingImages={formValues?.images?.map((img) => ({
                                id: img.asset?._id || img.asset?._ref,
                                url: img.asset?.url
                            })) || []} />
                        
                        <button 
                            className={styles.buttonDelete} 
                            onClick={(e) => {
                                e.preventDefault(); 
                                e.stopPropagation();
                                setShowDeleteModal(true);
                            }}
                        >
                            <FaTrashAlt />
                        </button>
                        <DeleteModal
                            isOpen={showDeleteModal}
                            onClose={() => setShowDeleteModal(false)}
                            onConfirm={handleDeleteBuilding}
                            entityName="le bâtiment"
                        />

                        <button 
                            type="button" 
                            onClick={handleSave} 
                            className={`${styles.buttonSave} ${hasChanges ? styles.pulse : ''}`}
                        >
                            Sauvegarder
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default BuildingForm;