// Function to fetch places from Google Places API
// const fetchNearbyPlaces = async (lat, lng, type, keyword) => {
//   const radius = 30000; // Define your search radius (e.g., 30 km)
//   const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=${radius}&type=${type}&keyword=${keyword}&key=${apiKey}`;

//   try {
//     const response = await fetch(url);
//     const data = await response.json();
//     return data.results;
//   } catch (error) {
//     console.error(`Error fetching places of type ${type} with keyword ${keyword}:`, error);
//     return [];
//   }
// };

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NearbyPlaces = ({ location, keywords, onFetchPlaces }) => {
  const [places, setPlaces] = useState([]);
  const [hasFetchedPlaces, setHasFetchedPlaces] = useState(false); // State to track if places have been fetched

  // Function to fetch nearby places from your backend API using only the keyword
  const fetchNearbyPlaces = async (lat, lng, keyword) => {
    const url = `${process.env.REACT_APP_API_URL}/public/api/places-info?lat=${lat}&lng=${lng}&keyword=${keyword}`; // Use your backend URL here

    try {
      const response = await axios.get(url);

      // Assuming response.data directly contains the array of places
      if (response.data && Array.isArray(response.data)) {

        // Add the keyword to each place object
        const placesWithKeyword = response.data.map(place => ({
          ...place,
          keyword, // Append the keyword used for search
        }));

        return placesWithKeyword;
      } else {
        console.error(`Unexpected response structure for keyword ${keyword}:`, response.data);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching places with keyword ${keyword}:`, error);
      return [];
    }
  };


  const removeDuplicatePlaces = (places) => {
    const uniquePlaces = new Map();
    places.forEach(place => uniquePlaces.set(place.place_id, place));
    return Array.from(uniquePlaces.values());
  };

  // Fetch all places for each keyword
  useEffect(() => {
    if (location && location.lat && location.lng && !hasFetchedPlaces) {
      const fetchAllNearbyPlaces = async () => {
        const allPlaces = [];

        for (const keyword of keywords) {
          try {
            const placesForKeyword = await fetchNearbyPlaces(location.lat, location.lng, keyword);
            allPlaces.push(...placesForKeyword); // Append results with keywords
          } catch (error) {
            console.error(`Error fetching places with keyword ${keyword}:`, error);
          }
        }

        const uniquePlaces = removeDuplicatePlaces(allPlaces); // Remove duplicates
        setPlaces(uniquePlaces);
        onFetchPlaces(uniquePlaces);
        setHasFetchedPlaces(true);
      };

      fetchAllNearbyPlaces();
    }
  }, [location, keywords, onFetchPlaces, hasFetchedPlaces]);

  return null;
};

export default NearbyPlaces;

