import React from 'react';
import ThoughtfulCastle from '../../assets/Images/Chateau_pensif_transparent.png'
import styles from '../../ProPortal.module.css';

const NoDataFallback = ({ message, onAction, actionLabel }) => {
    return (
        <div className={styles.noDataContainer}>
            <img 
                src={ThoughtfulCastle}
                alt="Thoughtful Castle" 
                className={styles.image}
            />
            <p>{message}</p>
            <button onClick={onAction} className={styles.createButton}>
                {actionLabel}
            </button>
        </div>
    );
};

export default NoDataFallback;
