import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'; // You can use other modal libraries too
import VenueDropdownMulti from './VenueDropDownMulti';
import SelectRoomModal from './SelectRoomModal';
import { FaTrash } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from '../../SelectModal.module.css'; // Custom styles
import '../../VenueDropdown.css';
import stylesVenue from '../../VenueForm.module.css';

const SelectSuiteModal = ({ suiteData, handleInputChange, onSave, onCancel }) => {
    const [isRoomModalOpen, setRoomModalOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [suite, setSuite] = useState({
        suiteName: suiteData?.suiteName || '',
        totalSuiteBeds: suiteData?.totalSuiteBeds || '',
        suiteFlags: suiteData?.suiteFlags || [],
        roomsInSuite: suiteData?.roomsInSuite || []
    })

    console.log('suite in modal:', suite);
    
    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            minHeight: '35%',
            maxHeight: '70%',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
            overflow: 'unset',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%',
                    },
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%',
                    },
                }));
            } else {
                setModalStyle((prevStyle) => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%',
                    },
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSaveModalRoom = (updatedRoom) => {
        setSuite((prevSuite) => {
            const updatedRooms = selectedRoom?.index !== null
                ? prevSuite.roomsInSuite.map((room, i) => i === selectedRoom.index ? updatedRoom : room)
                : [...prevSuite.roomsInSuite, updatedRoom];
                
            return {
                ...prevSuite,
                roomsInSuite: updatedRooms,
            };
        });
    
        setRoomModalOpen(false);
    };

    const removeRoom = (indexToRemove) => {
        setSuite((prev) => ({
            ...prev,
            roomsInSuite: prev.roomsInSuite.filter((_, index) => index !== indexToRemove),
        }));
    };

    // const handleSaveModalRoom = (updatedRoom) => {
    //     setSuite((prevSuite) => {
    //         const updatedRooms = selectedRoom
    //             ? prevSuite.roomsInSuite.map((room, i) => (i === selectedRoom.index ? updatedRoom : room))
    //             : [...prevSuite.roomsInSuite, updatedRoom];
    
    //         return {
    //             ...prevSuite,
    //             roomsInSuite: updatedRooms,
    //         };
    //     });
    //     setRoomModalOpen(false);
    // };

    // const removeRoom = (indexToRemove) => {
    //     setRoom((prevItems) => prevItems.filter((_, index) => index !== indexToRemove)); // Remove from the array
    // };

    const handleSave = () => {
        const updatedSuite = {
            ...suite,
            roomsInSuite: suite.roomsInSuite.map((room) => ({
                _key: room._key || '',
                name: room.name || '',
                beds: room.beds || 0,
                groomRoom: room.groomRoom || false,
                isInSuite: room.isInSuite || false,
                floor: room.floor ? room.floor : { label: '', value: '' }, // Default to a consistent floor structure
                bedSize: room.bedSize || [],
                equipment: room.equipment || [],
                images: room.images || [],
                flags: room.flags || [],
            })),
            suiteFlags: suite.suiteFlags.map((flag) => ({
                ...flag,
                title: flag.title || '',
                value: flag.value || '',
                _id: flag._id || '',
                iconUrl: flag.iconUrl || ''
            })),
            totalSuiteBeds: suite.totalSuiteBeds || 0,
            suiteName: suite.suiteName || '',
        };
    
        console.log('flattenedItem', updatedSuite); // Log to confirm structure
        onSave(updatedSuite); // Pass the updated suite with consistent room structure
    };

    const openRoomModal = (index = null) => {
        console.log('opening modal with room index ', index)
        const selectedRoomData = index !== null 
            ? { ...suite.roomsInSuite[index], index }
            : { 
                name: '',
                beds: '',
                flags: [],
                groomRoom: false,
                isInSuite: false,
                floor: '',
                bedSize: [],
                equipment: [],
                images: [],
                index: null 
            };
        setSelectedRoom(selectedRoomData);
        console.log('selectedRoom in openRoomModal', selectedRoom)
        setRoomModalOpen(true);
    };

    const closeRoomModal = () => {
        setRoomModalOpen(false);
    };

    return (
        <Modal isOpen={true} onRequestClose={onCancel} style={modalStyle}>
            <h2 className={styles.modalTitle}> Configurez la suite {suite.name}</h2>

            <label>Nom de la suite</label>
            <input
                type="text"
                name="suiteName"
                value={suite.suiteName}
                onChange={(e) => setSuite({ ...suite, suiteName: e.target.value })}
                required
                className={styles.customModalInput}
            />

            <label>Nombre de couchage</label>
            <input
                type="number"
                name="totalSuiteBeds"
                value={suite.totalSuiteBeds}
                onChange={(e) => setSuite({ ...suite, totalSuiteBeds: e.target.value })}
                placeholder='La nombre de couchages dans cette chambre.'
                className={styles.customModalInput}
            />

            <VenueDropdownMulti
                type="roomFlag"
                name="roomFlag"
                label="Indiquez pour quel(s) groupe(s) cette chambre est adaptée"
                value={suite.suiteFlags}
                onChange={(selectedOptions) => {
                    setSuite({ ...suite, suiteFlags: selectedOptions });
                }}
            />

            <div className={stylesVenue.venueBuildingRoom}>
                {/* Display selected items */}
                <div className={stylesVenue.selectedItemsContainer}>
                    {suite.roomsInSuite.length > 0 ? (
                        suite.roomsInSuite.map((room, index) => (
                            <div 
                                key={index} 
                                className={stylesVenue.selectedItem}
                                onClick={() => openRoomModal(index)}
                            >
                                <div className={stylesVenue.itemDetails}>
                                    <span className={stylesVenue.selectedSpan}>{room.name}</span>
                                    <span className={stylesVenue.selectedSpan}>| Couchages: {room.beds}</span>
                                    <span className={stylesVenue.selectedSpan}>| Étage: {room.floor?.label || room.floor}</span>
                                    <div className={stylesVenue.iconContainer}>
                                        <div className={stylesVenue.verticalLine}></div>
                                        <button
                                            className={stylesVenue.deleteButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeRoom(index);
                                            }}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={stylesVenue.noItemsText}>Aucune chambre encore créée</p>
                    )}
                </div>
                <button type="button" className={stylesVenue.openModalButton} onClick={(e) => { e.preventDefault(); openRoomModal(); }}>
                    <FontAwesomeIcon icon={faPlus} className={stylesVenue.chevronIcon} /> Ajoutez une chambre
                </button>

                {isRoomModalOpen && (
                    <SelectRoomModal
                        roomData={selectedRoom} // Pass buildingData to the modal
                        //handleInputChange={handleInputChange} // Pass the change handler to update state
                        onSave={handleSaveModalRoom}
                        onCancel={closeRoomModal}
                    />
                )}
            </div>

            <div className={styles.modalButtonsRoom}>
                <button className={styles.cancelButton} onClick={onCancel}>
                    Annuler
                </button>
                <button className={styles.saveButton} onClick={handleSave}>
                    Confirmer
                </button>
            </div>
        </Modal>
    );
};

export default SelectSuiteModal;