import React, { useState, useEffect } from 'react';
import {
  fetchSanityVenueRules,
  fetchSanityVenuePMR,
  fetchSanityVenuePets,
  fetchSanityVenueSmoking,
  fetchSanityEquipments,
  fetchSanityEntertainment,
  fetchSanityBedSizes,
  fetchSanityFlags,
  fetchSanityParkingType,
  fetchSanityRentingItems,
  fetchSanityRoomFlags
} from './VenueSanityHelpers';
import { matchFloorData } from '../../utils/matchingFloorData';
import { FaChevronDown } from 'react-icons/fa';
import '../../VenueDropdown.css';

const VenueDropdown = ({ type, label, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        switch (type) {
          case 'rules':
            data = await fetchSanityVenueRules();
            break;
          case 'smoking':
            data = await fetchSanityVenueSmoking();
            break;
          case 'PMR':
            data = await fetchSanityVenuePMR();
            break;
          case 'pets':
            data = await fetchSanityVenuePets();
            break;
          case 'equipment':
            data = await fetchSanityEquipments();
            break;
          case 'entertainment':
            data = await fetchSanityEntertainment();
            break;
          case 'bedsize':
            data = await fetchSanityBedSizes();
            break;
          case 'flag':
            data = await fetchSanityFlags();
            break;
          case 'parkingType':
            data = await fetchSanityParkingType();
            break;
          case 'itemType':
            data = await fetchSanityRentingItems();
            break;
          case 'roomFlag':
            data = await fetchSanityRoomFlags();
            break;
          case 'floor':
            data = [
              { value: 'rdc', label: 'RDC', iconUrl: '' },
              { value: '1er', label: '1er étage', iconUrl: '' },
              { value: '2e', label: '2e étage', iconUrl: '' },
              { value: '3e', label: '3e étage', iconUrl: '' },
              { value: '4e', label: '4e étage', iconUrl: '' },
              { value: '5e', label: '5e étage', iconUrl: '' },
            ];
            break;
          default:
            console.error(`Unknown type: ${type}`);
            return;
        }

        const transformedOptions = data.map((option) => ({
          value: option._id || option.value,
          label: option.title || option.label,
          iconUrl: option.iconUrl,
        }));
        setOptions(transformedOptions);

        // Set `selectedOption` to the option object matching the full `value`
        // const initialOption = transformedOptions.find(opt => opt.value === value);
        // setSelectedOption(initialOption || null);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [type, value]);

  // console.log('value in dropdown', value)
  // console.log('selectedOption in dropdown', selectedOption)

  useEffect(() => {
    if (type === 'floor' && typeof value === 'string') {
      const matchedOption = matchFloorData(value);
      setSelectedOption(matchedOption || null);
    } else {
      const initialValue = Array.isArray(value) ? value[0] : value;
      const matchedOption = options.find(opt => opt.value === initialValue?.value || opt.label === initialValue?.label);
      setSelectedOption(matchedOption || null);
    }
  }, [value, options]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    if (onChange) {
      onChange(option); // Pass the entire option object to the parent component
    }
  };

  return (
    <div className="dropdown-container">
      <label className='dropdown-label'>{label}</label>
      <div
        className="dropdown-custom"
        tabIndex="0"
        onClick={toggleDropdown}
      >
        <div className="dropdown-selected">
          <div className="dropdown-item">
            {selectedOption ? (
              <>
                {selectedOption.iconUrl && (
                  <img src={selectedOption.iconUrl} alt={selectedOption.label || selectedOption.title} className="dropdown-icon" />
                )}
                <span>{selectedOption.label || selectedOption.title}</span>
              </>
            ) : (
              <span className='dropdown-placeholder'>Veuillez sélectionner une option</span>
            )}
          </div>
          <div className='icon-container'>
            <div className="vertical-line"></div>
            <FaChevronDown className={`chevron-icon ${dropdownOpen ? 'open' : ''}`} />
          </div>
        </div>

        {dropdownOpen && (
          <div className="dropdown-options">
            {options.map((option, index) => (
              <div key={index} className="dropdown-item" onClick={() => handleOptionClick(option)} >
                {option.iconUrl && (
                  <img src={option.iconUrl} alt={option.label} className="dropdown-icon" />
                )}
                <span>{option.label || option.title}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueDropdown;